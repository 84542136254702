// fonts
$baseFontSize: 16;
$baseFont: "Open Sans", sans-serif;
$headingFont: "Now";

// color
$darkGray: #2a2a2a;
$white: #fff;
$black: #000;
$magen: #00c6d1;
$midGray: #525252;
$blue: #242f6c;

$themePrimarCcolor: $magen;
$bodyBgColor: $white;
$sectionBgColor: #edecec;
$textColor: $midGray;
$headingColor: $blue;
