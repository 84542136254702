.itemDetails {
  .previewImg {
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 30px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  ul {
    li {
      font-size: 16px;
      padding-left: 25px;
      position: relative;
      margin-top: 8px;

      &::before {
        content: "";
        background-color: #00c6d1;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      a {
        display: inline-block;
        color: #242f6c;

        &:hover {
          color: #00c6d1;
        }
      }
    }
  }
}
