@import "./../../../../docs/css/veriabls.scss";

.newCardWrap {
  .newCardItem {
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.2);
    border-radius: 0px;
    padding: 10px;

    img {
      margin-bottom: 10px;
    }
  }

  .newCardItemChildList {
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.2);
    border-radius: 0px;
    padding: 10px;

    img {
      margin-bottom: 10px;
      height: 80%;
      width: 100%;
    }
  }

  .cardButton {
    display: flex;
    flex-direction: column;
  }
  .cardRoot {
  }
  .cardRootChildList {
    width: 100%;
  }
}
