.sectionTitle {
  text-align: center;
  margin-bottom: 20px;

  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width:767px) {
      line-height: 40px;
    }
  }

  p {
    padding: 0px 300px;

    @media (max-width:1279px) {
      padding: 0px 230px;
    }

    @media (max-width:991px) {
      padding: 0px 130px;
    }

    @media (max-width:767px) {
      padding: 0px;
    }
  }
}
