	/*
  	Flaticon icon font: Flaticon
  	Creation date: 02/09/2019 05:24
  	*/

	@font-face {
	  font-family: "Flaticon";
	  src: url("../fonts/Flaticon.eot");
	  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	    url("../fonts/Flaticon.woff2") format("woff2"),
	    url("../fonts/Flaticon.woff") format("woff"),
	    url("../fonts/Flaticon.ttf") format("truetype"),
	    url("../fonts/Flaticon.svg#Flaticon") format("svg");
	  font-weight: normal;
	  font-style: normal;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
	  @font-face {
	    font-family: "Flaticon";
	    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	  }
	}

	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
	  font-family: Flaticon;
	  font-size: 20px;
	  font-style: normal;
	  margin-left: 0px;
	}

	.flaticon-doctor:before {
	  content: "\f100";
	}

	.flaticon-suitcase:before {
	  content: "\f101";
	}

	.flaticon-edit:before {
	  content: "\f102";
	}

	.flaticon-heart:before {
	  content: "\f103";
	}

	.flaticon-fork:before {
	  content: "\f104";
	}

	.flaticon-rocket:before {
	  content: "\f105";
	}

	.flaticon-supermarket:before {
	  content: "\f106";
	}

	.flaticon-menu:before {
	  content: "\f107";
	}

	.flaticon-weather:before {
	  content: "\f108";
	}

	.flaticon-heart-1:before {
	  content: "\f109";
	}

	.flaticon-celtic:before {
	  content: "\f10a";
	}

	.flaticon-flower:before {
	  content: "\f10b";
	}

	.flaticon-send:before {
	  content: "\f10c";
	}

	.flaticon-insect:before {
	  content: "\f10d";
	}

	.flaticon-house:before {
	  content: "\f10e";
	}

	.flaticon-light-bulb:before {
	  content: "\f10f";
	}

	.flaticon-diamond:before {
	  content: "\f110";
	}

	.flaticon-chef-hat-outline-symbol:before {
	  content: "\f111";
	}

	.flaticon-law:before {
	  content: "\f112";
	}

	.flaticon-law-1:before {
	  content: "\f113";
	}

	.flaticon-network:before {
	  content: "\f114";
	}

	.flaticon-download:before {
	  content: "\f115";
	}

	.flaticon-emoji:before {
	  content: "\f116";
	}

	.flaticon-team:before {
	  content: "\f117";
	}

	.flaticon-cite:before {
	  content: "\f118";
	}

	.flaticon-star:before {
	  content: "\f119";
	}

	.flaticon-airplane:before {
	  content: "\f11a";
	}

	.flaticon-plane:before {
	  content: "\f11b";
	}

	.flaticon-rocket-1:before {
	  content: "\f11c";
	}

	.flaticon-tick:before {
	  content: "\f11d";
	}

	.flaticon-chat:before {
	  content: "\f11e";
	}

	.flaticon-internet:before {
	  content: "\f11f";
	}

	.flaticon-mail:before {
	  content: "\f120";
	}

	.flaticon-calendar:before {
	  content: "\f121";
	}

	.flaticon-heart-2:before {
	  content: "\f122";
	}

	.flaticon-quote-1:before {
	  content: "\f123";
	}

	.flaticon-magnifying-glass:before {
	  content: "\f124";
	}

	.flaticon-play-button:before {
	  content: "\f125";
	}

	.flaticon-ui:before {
	  content: "\f126";
	}

	.flaticon-support:before {
	  content: "\f127";
	}

	.flaticon-first:before {
	  content: "\f128";
	}

	.flaticon-checklist:before {
	  content: "\f129";
	}

	.flaticon-muscles:before {
	  content: "\f12a";
	}

	.flaticon-shopping-cart:before {
	  content: "\f12b";
	}

	.flaticon-favorites:before {
	  content: "\f12c";
	}

	.flaticon-quote:before {
	  content: "\f12d";
	}

	.flaticon-bag:before {
	  content: "\f12e";
	}
