.img-container {
  position: relative;
  width: 80px;
  height: 80px;
}

.image {
  /* display: block; */
  width: 100%;
  height: 100%;
  border-radius: 40px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: red;
  border-radius: 40px;
  object-fit: cover;
}

.img-container:hover .overlay {
  opacity: 1;
  cursor: pointer;
  border-radius: 40px;
  object-fit: cover;
}

.icon {
  color: white;
  /* font-size: 100px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-user:hover {
  color: white;
}

.change-button {
  cursor: pointer;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: "flex";
  align-items: center;
}
